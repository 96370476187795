const JSZip = require('jszip');

// const {promisify} = require('util');
const pdfjsLib = window['pdfjs-dist/build/pdf'];

const input = document.getElementById('file');
const jpg = document.getElementById('jpg');
const pagesInput = document.getElementById('pages');

async function pageToImg(page) {
  var viewport = page.getViewport({scale: 1});
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  // Render PDF page into canvas context
  var renderContext = {
    canvasContext: ctx,
    viewport: viewport
  };
  await page.render(renderContext).promise;
  return canvas.toDataURL("image/jpg")
}

jpg.addEventListener('click', async () => {
  if (input.files.length == 0) {
    alert('请上传文件');
  }
  const file = input.files[0];
  const pages = pagesInput.value.split('\n').map(x => x.trim()).filter(x => x).flatMap(x => {
    const elems = x.split('-');
    if (elems.length > 2) {
      alert("每行格式：1-3 or 1");
    }
    if (elems.length == 1) {
      elems.push(elems[0]);
    }
    const start = parseInt(elems[0]);
    const end = parseInt(elems[1]);
    res = [];
    for (let i = start; i <= end; i++) {
      res.push(i);
    }
    return res;
  });

  const pdf = await pdfjsLib.getDocument({data: await file.arrayBuffer()}).promise;
  const ps = [];
  for (const page of pages) {
    ps.push((async () => {
      return await pageToImg(await pdf.getPage(page));
    })());
  }
  const uris = await Promise.all(ps);
  if (uris.length == 1) {
    window.location.href = uris[0];
    return;
  }
  var zip = new JSZip();
  for (const [i, uri] of uris.entries()) {
    const content = uri.substr(uri.indexOf(',')+1);
    zip.file(String(pages[i])+".jpg", content, {base64: true});
  }
  const content = await zip.generateAsync({type: "blob"});
  window.location.href = window.URL.createObjectURL(content);
});
